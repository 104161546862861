.up {
  margin-top: 10px;
}

.down {
  margin-bottom: 10px;
}

.select {
  text-align: left !important;
}

.products-page {
  display: flex;

  &__select {
    width: 100px;

    &-sort {
      cursor: pointer;
      margin-left: 10px;
    }

    &-text {
      margin-right: 10px;
    }

    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      width: 200px;
    }
  }

  &__row {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-family: Roboto, sans-serif;
    font-weight: 100;
    justify-content: space-between;
    letter-spacing: 1.7px;
  }

  &__check-all {
    align-items: center;
    display: flex;

    &-text {
      margin-right: 5px;
      padding-top: 1px;
    }
  }

  &-content {
    padding: 30px 30px 60px;

    &-gallery {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-bottom: 60px;
    }
  }

  &-view {
    color: #595959;
    display: flex;
    font-family: Roboto, sans-serif;
    font-weight: 100;
    letter-spacing: 1.7px;
    margin: 10px 0;
    padding: 0 15px;
  }

  &-switch {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 10px;
  }
}
