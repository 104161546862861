.products-page {
  flex-grow: 1;
  overflow: scroll;

  &__content {
    width: 100% !important;
  }
}

.modal-filter {
  &__title {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 15px;
    margin-top: 20px;
    text-align: center;
    text-transform: uppercase;
  }

  &__filters {
    display: flex;
  }

  &__footer {
    text-align: center;
  }
}

.filters {
  &__one-column {
    display: flex;
    flex-direction: column;
    height: 640px;
    margin: 0 0.7rem 0 0;
    width: 700px;
  }
}
