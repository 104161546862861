.filter-sidebar {
  flex: 0 0 auto;
  padding-right: 10px;
  padding-top: 50px;
  width: 300px;

  &__loader {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & input {
    margin: 10px 0;
    width: 65px;
  }

  & .select {
    width: 150px;
  }

  &__inputs {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__slider {
    height: 20px;
    width: 100px;
  }

  &__button {
    background-color: #1ab394;
    border: none;
    border-radius: 15.6px;
    color: white;
    cursor: pointer;
    font-family: Roboto;
    font-size: 15.5px;
    font-weight: bold;
    height: 46px;
    letter-spacing: 1.6px;
    line-height: 3.1;
    margin: 5px auto;
    text-align: center;
    width: 100%;

    &:hover {
      background-color: #14775f;
      color: white;
      opacity: 0.8;
      transition: all 0.5s;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__content {
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
  }

  &__item {
    font-size: 14px;

    &-label {
      margin-left: 5px;
    }
  }
  &__dimensions_ranges{
    margin-bottom: 40px;
    .rc-slider {
      margin-bottom: 20px;

      .rc-slider-rail {
        background-color: #ddd;
      }

      .rc-slider-track {
        background-color: black;
      }

      .rc-slider-handle {
        border: 1px solid black;
        background-color: white;
        width: 16px;
        height: 16px;
        margin-top: -7px;
        opacity: 1;
      }

      .rc-slider-handle-click-focused {
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.accordion {
  border: unset;

  &__button {
    border: unset;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    width: unset;
  }

  &__item {
    border-top: unset !important;

    .filter-sidebar__range-values{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .filter-sidebar__range-min-wrapper, .filter-sidebar__range-max-wrapper{
        border-bottom: 1px solid grey;
      }
    }
  }
}

#filters-id {
  width: 100%;
}
