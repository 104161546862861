@import "~react-image-gallery/styles/scss/image-gallery";

.product-gallery {
  align-items: center;
  background-color: #fff;
  border: solid 1px #eee;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: 100;
  margin: 8px;
  padding: 8px;
  width: 260px;

  &__check {
    height: 0;
    margin: 10px auto 12px 10px;
    position: relative;
    width: 0;
  }

  &__image {
    flex: 0 0 auto;
    height: 160px;
    margin: 0 auto 5px;
    width: 160px;
  }

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 100%;

    &:hover {
      opacity: 0.7;
    }

    &-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }

    &-dimensions {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: space-between;
      margin: 0;
      padding: 0 5px;
    }

    &-cost {
      background-color: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      height: 100%;
      justify-content: space-between;
      padding: 0 5px;
      width: 100%;
    }

    &-dimensions-stock,
    &-date,
    &-sku,
    &-supplier {
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      font-weight: 100;
      justify-content: center;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 50%;
    }

    &-date {
      font-size: 12px;
      width: unset;
    }

    &-sku,
    &-dimensions-stock {
      text-transform: uppercase;
      width: 100%;
    }

    &-header {
      display: flex;
      flex-direction: row;
    }

    &-name {
      align-items: center;
      color: #233645;
      flex: 0 0 auto;
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      padding: 0;
      text-align: center;
    }
  }
}

.image-gallery {
  height: 160px;
  width: 160px;

  &-content,
  &-slide-wrapper,
  &-slide,
  &-image,
  &-slides {
    height: 100%;
  }

  &-icon {
    filter: none;
  }

  &-left-nav {
    left: -58px !important;
  }

  &-right-nav {
    right: -58px !important;
  }

  &-svg {
    height: 45px !important;
    stroke: #253544;
  }

  &-fullscreen-button {
    right: -58px !important;
    top: 95px !important;
  }
}
