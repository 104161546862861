/* stylelint-disable selector-class-pattern */
.suppliers {
  width: 100%;

  &__table {
    display: flex;
  }

  & .filter-sidebar {
    width: 220px;
  }

  & .furnishr-table {
    &__sorting-toggle-arrows {
      display: none;
    }

    &__header-cell-content-wrapper {
      font-weight: 500;
      height: 20px;
    }

    &__cell {
      font-size: 12px;
      font-weight: 300;
    }
  }

  &__search {
    &-suppliers {
      display: flex;
      margin-bottom: 20px;
      margin-left: 220px;
      width: 100%;
    }

    &-input {
      display: flex;
      width: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px;
    width: 100%;
  }

  &__title {
    color: #434343;
    font-size: 45px;
    font-weight: 700;
    margin: 20px 240px;
    text-transform: uppercase;
  }

  &__tabs {
    border-bottom: 3px solid #888;
    display: flex;
    margin-bottom: 20px;
    margin-top: 10px;
    width: 100%;
  }

  &__tab {
    align-items: center;
    border-bottom: none;
    border-left: 3px solid #888;
    border-right: 3px solid #888;
    border-start-end-radius: 7px;
    border-start-start-radius: 7px;
    border-top: 3px solid #888;
    color: #888;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin-right: 20px;
    width: 200px;

    &:first-child {
      margin-left: 10px;
    }

    &--active {
      background-color: #888;
      color: white;
    }
  }
}

ul[data-garden-id="pagination.pagination_view"] {
  display: flex !important;
}
