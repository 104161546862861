.attributes-form {
  line-height: 42px;

  &__categories {
    min-width: 145px;
  }

  .form-control {
    color: #000;
  }

  .is-invalid {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
  }

  & .select2 > div > div > div {
    background-color: transparent;
  }

  .invisible-input {
    display: none;
  }

  .rct-title {
    position: relative;
    top: -12px;
  }

  .select2 {
    border: 0;
    font-size: 22px;
    margin-bottom: 15px;
  }

  .select2-single {
    font-size: 18px;
  }

  .select2 > div {
    background-color: #f7f7f7;
    border: 0;
    border-radius: 0;
    outline: 0;
  }

  .select2 > div > div > div {
    background-color: transparent !important;
  }

  .select2 > div:hover {
    border-color: #f7f7f7;
    box-shadow: none;
  }

  .upload-button {
    margin: 0;
    text-align: center;
    width: 189px;
  }

  .row {
    .upload-button {
      margin: 0;
      text-align: center;
      width: 189px;
    }

    .default-image-input {
      display: none;
    }
  }

  .btn-category {
    margin-bottom: 20px;
  }

  .checkboxTreeContainer {
    height: 200px;
    margin-bottom: 20px;
    overflow: scroll;
  }
}

.attributes-form {
  &__label {
    align-items: center;
    display: flex;
    padding-right: 0 !important;
  }

  &__input {
    padding-left: 0 !important;
  }

  &__link {
    color: #000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 70%;

    &:hover {
      opacity: 0.6;
    }

    &-container {
      align-items: center;
      display: flex;
      margin-bottom: 10px;
      width: 100%;
    }

    &-date {
      font-size: 12px;
      width: 30%;
    }
  }

  &__product-used {
    border: 1px solid #000;
    border-radius: 10px;
    margin: 10px;

    &-list {
      height: 255px;
      overflow: scroll;
    }
  }
}
