/* stylelint-disable selector-class-pattern */
.supplier-view {
  width: 100%;

  &__back-button {
    position: relative;
    top: 50px;
    left: 20px;
    width: 100px;
    margin-right: 10px;

    & button {
      width: 100%;
    }
  }

  &__form {
    height: 100%;
    margin: 0 auto;
    margin-top: 50px;
    overflow-y: auto;
    width: 60%;

    & input {
      width: 220px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin: 20px auto 0;
      width: 50%;

      & button {
        width: 100%;
      }
    }

    &-element {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      & .select {
        border-color: #ccc !important;
        font-family: Roboto, sans-serif;
        font-weight: normal;
        width: 220px;

        &:hover {
          border-color: #595959 !important;
        }

        &__control {
          border-radius: 0;
          cursor: pointer !important;

          &--is-focused {
            border-color: #595959 !important;
            box-shadow: unset !important;
          }
        }

        &__placeholder {
          font-weight: 300;
        }

        &__value-container {
          padding: 11px;
        }
      }

      & .form-control-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}
