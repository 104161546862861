.header {
  &.room {
    background: #fff;
    margin-left: 0;
    min-height: 273px;
    padding-top: 88px;
  }

  p {
    margin-bottom: 0;
  }

  &__button {
    margin-left: 5px;
  }

  &__select {
    width: 250px;

    &:disabled {
      cursor: not-allowed !important;
      opacity: 0.5;
    }
  }

  &__text {
    font-size: 13px;
    margin-right: 10px;
    margin-top: 5px;
  }

  &__input {
    margin-right: 10px;
  }

  .room-name {
    font-size: 41px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    height: 61px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .room-breadcrumbs {
    align-items: start;
    color: #595959;
    font-family: Roboto;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: auto;
    letter-spacing: 2.6px;
    line-height: 2;
    overflow: hidden;
    text-align: left;

    &__item {
      display: inline-block;
    }

    &__item-link {
      color: #595959;
      min-width: 2rem;
      text-decoration: none;

      &:hover {
        color: #595959;
        text-decoration: underline;
      }
    }

    &__item-current {
      color: #595959;
      min-width: 5rem;
    }

    &__item-arrow {
      text-align: center;
      width: 2.5rem;
    }
  }

  .breadcrumbs__permalink-editable {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .name-validation-wrapper {
    width: 100%;
  }

  .path {
    color: #595959;
    font-size: 26px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 25px;
    text-align: left;
    width: 434px;
  }

  .switch-name {
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 9px;
    margin-bottom: 10px;
    margin-right: 14px;
    padding-left: 9px;
    width: 45px;
  }

  .open-link-container {
    margin-top: 17px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    .breadcrumbs {
      font-size: 18px;
      text-align: center;
    }

    .room-name {
      font-size: 32px;
    }
  }
}
