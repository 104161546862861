/* stylelint-disable selector-class-pattern */
.delivery-agent-view {
  width: 100%;

  &__table {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    overflow-y: auto;
    width: 50%;
  }

  &__tables {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
  }

  &__form {
    height: 100%;
    margin: 0 auto;
    margin-top: 50px;
    overflow-y: auto;
    width: 60%;

    & input {
      width: 220px;
    }

    &-buttons {
      display: flex;
      justify-content: space-between;
      margin: 20px auto 0;
      width: 50%;

      & button {
        width: 100%;
      }
    }

    &-element {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      & .select {
        width: 220px;
      }

      & .form-control-wrapper {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }
}

ul[data-garden-id="pagination.pagination_view"] {
  display: flex !important;
}
