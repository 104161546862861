.furnishr-table {
  border: 1px solid #cdcdcd;
  border-collapse: collapse;
  width: 100%;

  &-cont {
    box-sizing: border-box;
    color: #434343;
    margin-bottom: 30px;
    overflow: auto;
    width: 100%;
  }

  &__header {
    background-color: #dbdbdb;
  }

  &__sorting-toggle-arrows {
    display: flex;
    flex-direction: column;
  }

  &__row {
    background-color: #fff;
    height: 3rem;
  }

  &__row:nth-child(even) {
    background-color: #f7f7f7;
  }

  &__cell {
    border-left: 1px solid #cdcdcd;
    border-right: 1px solid #cdcdcd;
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 14.5px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 1.07;
    max-width: 200px;
    padding: 3px 6px;
    text-align: left;
    vertical-align: middle;
    white-space: normal;
    width: 1%;

    &-admin_contact_name,
    &-admin_contact_number {
      white-space: normal;
      width: 100px;
    }

    &-address {
      line-break: strict;
      white-space: normal;
      width: 170px;
    }

    &-company-name {
      line-break: strict;
      white-space: normal;
      width: 100px;
    }

    &-orders,
    &-quotes {
      cursor: pointer;
      font-weight: 500 !important;
      text-decoration: underline;
    }

    &_name {
      cursor: pointer;
      white-space: normal;
    }

    &_thumbnail {
      text-align: center;

      img {
        height: auto;
        width: 30px;
      }
    }
  }

  &__header-cell-content-wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  &__checkbox-cell {
    display: flex;
  }

  &__header,
  &__cell {
    font-weight: 500;
    padding: 8px 6px;
    vertical-align: middle;
    white-space: nowrap;

    &-view-more {
      cursor: pointer;
      font-weight: 500 !important;
      text-decoration: underline;
      color: #434343;
    }
  }

  &__img {
    height: 30px;
    width: auto;
  }

  &__cell-actions {
    background-color: transparent;
    border: solid 1px #616161;
    border-radius: 13px;
    color: #434343;
    cursor: pointer;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    height: 26px;
    padding: 2px;
    width: 50px;
  }

  &__header .checkbox {
    padding: 8px 0.75rem 0;
    width: 100%;
  }
}

.dataStatus-cell {
  border: 1px solid #b8b8b8 !important;
}

.furnishr-table__cell-filename-container{
  display: flex;
  .furnishr-table__cell-import-filename{
    white-space: normal;
  }
}
